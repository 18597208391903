// @flow
import React from 'react';
import loadable from '@loadable/component';
import LazyLoad from 'react-lazyload';
import Layout from '../../components/Layout/Layout';
import Hero from '../../components/Merchant/shared/Hero';
import Bg from '../../images/case_study.svg';
import ApplySection from '../../components/Merchant/ApplySection';

const Navigation = loadable(() =>
  import(
    /* webpackPreload: true */ '../../components/Merchant/CaseStudy/Navigation'
  )
);

const CaseStudyPage = () => {
  return (
    <Layout isMerchant>
      <LazyLoad height={400} offset={100} once>
        <Hero title="導入事例" bg={Bg} />
      </LazyLoad>
      <LazyLoad height={500} offset={400} once>
        <Navigation />
      </LazyLoad>
      <ApplySection />
    </Layout>
  );
};

// $FlowFixMe
export default CaseStudyPage;
